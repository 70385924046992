a {
  text-decoration: none !important;
}

.my-homepage {
  min-height: 100vh;
  text-align: center;
  padding-top: 5vh;
}

.home-logo {
  margin-bottom: 1rem;
}

.home-logo img {
  height: 100px;
  width: 200px;
}

.my-homepage-cat {
  display: flex;
  align-items: center;
  background: rgb(240, 240, 240);
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
}

.my-homepage-cat img {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  margin-right: 10px;
}

.my-homepage-cat div {
  text-align: start;
  color: #000;
}
