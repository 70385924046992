.view-order-card {
  padding-top: 1rem;
  min-height: 100vh;
}

.view-order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.view-order-header div:first-child {
  font-size: 25px;
  font-weight: 700;
}

.view-order-header a {
  font-size: 20px;
  color: rgb(42, 221, 42);
  font-weight: 700;
}

.view-order-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
}

.view-order-product-left {
  display: flex;
}

.view-order-product-left img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  object-fit: cover;
}

.view-order-product-right {
  font-size: 17px;
  font-weight: 600;
  color: green;
}

/* =================================== */
.view-order-pay {
  width: 100%;
  background: rgb(0, 167, 6);
  padding: 10px;
  text-align: center;
  position: sticky;
  bottom: 0;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}
