.search-div {
  margin-bottom: 10px;
}

.my-searchbar input {
  padding: 4px 10px;
  border-radius: 5px;
  border: grey solid 1px;
  width: 100%;
  font-size: 17px;
}

.selected-sub-cat {
  background: #fff !important;
  color: rgb(105, 108, 255, 0.9) !important;
  border: 1px solid rgb(105, 108, 255, 0.9);
  font-weight: 600;
}

.search-bar-sub-cat {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
}

.search-bar-sub-cat::-webkit-scrollbar {
  display: none;
}

.search-bar-sub-cat {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.search-bar-sub-cat div {
  display: inline-block;
  background-color: rgb(105, 108, 255, 0.9);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.all-products {
  min-height: 75vh;
}

.item-card {
  height: 100%;
  padding: 5px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
  display: grid;
}

.item-card img {
  width: 100%;
  height: 20vh;
  object-fit: cover;
}

.item-card-title {
  font-size: 17px;
  font-weight: 600;
}

.item-card-price {
  color: green;
  font-size: 16px;
  font-weight: 600;
}

.buttons-container {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
}

.add-button {
  width: 100%;
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(248, 169, 23);
  font-weight: 700;
  color: #fff;
  font-size: 17px;
}

.plus-button {
  width: 48%;
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(248, 169, 23);
  font-weight: 700;
  color: #fff;
}

.minus-button {
  width: 48%;
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 39, 39);
  color: #fff;
  font-weight: 700;
}

.number-of-selected-item {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(248, 169, 23);
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: grid;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 550;
  font-size: 18px;
}

/* ========================= */
.view-order {
  width: 100% !important;
  background: rgb(0, 167, 6);
  padding: 10px;
  text-align: center;
  /* position: sticky; */
  /* bottom: 0; */
  color: #fff;
}
